import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class AboutMission {
  constructor() {
    this.main = document.querySelector('.js-about-mission-main')
    if (this.main === null) return

    this.section = document.querySelector('.js-about-mission')
    this.bg = document.querySelector('.js-about-mission-bg')
    this.grid = document.querySelector('.js-about-mission-grid')

    const activeTrigger = () => {
      // 背景を固定
      ScrollTrigger.create({
        trigger: this.section,
        start: 'top top',
        end: 'bottom bottom',
        pin: this.bg,
        // markers: true,
        pinSpacing: false,
        anticipatePin: 1,
        onEnter: () => {
          this.bg.classList.add('is-act')
          this.grid.classList.add('is-act')
        },
        onLeaveBack: () => {
          this.bg.classList.remove('is-act')
          this.grid.classList.remove('is-act')
        },
      })
    }

    window.addEventListener('load', () => {
      activeTrigger()
    })

    // let lastWindowWidth = window.innerWidth

    window.addEventListener('resize', () => {
      // if (lastWindowWidth != window.innerWidth) {
      //   lastWindowWidth = window.innerWidth
      //   window.location.reload()
      // }
      ScrollTrigger.refresh()
    })
  }
}

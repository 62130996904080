export class ManufacturingMap {
  constructor() {
    this.section = document.getElementById('js-manufacturing-map')
    if (this.section === null) return

    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas.getContext('2d')
    this.img = new Image()
    this.img.src = '../img/manufacturing/kv_illust02.png'

    this.circle = {
      x: 0,
      y: 0,
      radius: 200, // 円の半径
    }

    this.mouse = {
      x: 0,
      y: 0,
    }

    this.img.onload = () => {
      this.updateCanvasSize()
      this.tick()
    }

    this.canvas.addEventListener('mousemove', this.updateMousePosition.bind(this))
    window.addEventListener('resize', this.updateCanvasSize.bind(this))
  }

  updateMousePosition(e) {
    const rect = this.canvas.getBoundingClientRect()
    this.mouse.x = e.clientX - rect.left
    this.mouse.y = e.clientY - rect.top
  }

  updateCanvasSize() {
    const aspectRatio = this.img.width / this.img.height
    const maxWidth = 1366

    // sectionの幅を取得し、最大幅を超えないようにする
    const sectionWidth = Math.min(this.section.clientWidth, maxWidth)

    // キャンバスの幅と高さを更新
    this.canvas.width = sectionWidth
    this.canvas.height = sectionWidth / aspectRatio

    // 必要なら円の位置もリセット
    this.circle.x = this.canvas.width / 2
    this.circle.y = this.canvas.height / 2
  }

  drawCircle() {
    this.ctx.beginPath()
    this.ctx.arc(this.circle.x, this.circle.y, this.circle.radius, 0, 2 * Math.PI)
    this.ctx.fillStyle = '#000000'
    // ぼかしの効果を設定
    this.ctx.shadowBlur = 80 // ぼかしの強さ
    this.ctx.shadowColor = '#fff'
    this.ctx.fill()
  }

  draw() {
    this.ctx.globalCompositeOperation = 'source-over'
    this.ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height)
    this.ctx.globalCompositeOperation = 'destination-in'
    this.drawCircle()
  }

  updateCirclePosition() {
    const dx = this.mouse.x - this.circle.x
    const dy = this.mouse.y - this.circle.y
    const easing = 0.1 // 遅延の度合い
    this.circle.x += dx * easing
    this.circle.y += dy * easing
  }

  tick() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.updateCirclePosition()
    this.draw()
    requestAnimationFrame(this.tick.bind(this))
  }
}

/**
 * ドロワーメニューの開閉機能
 */
export class Drawer {
  constructor() {
    const drawer = document.getElementById('js-drawer')
    const buttons = [...document.getElementsByClassName('js-drawer-button')]
    const page = document.getElementById('page')
    const body = document.body

    if (drawer === null || buttons.length === 0 || page === null) return

    if (document.getElementById('js-overlay') === null) {
      const overlay = document.createElement('div')
      overlay.id = 'js-overlay'
      overlay.className = 'c-overlay'
      page.insertBefore(overlay, page.firstChild)
    }

    let isOpen = false

    const open = () => {
      isOpen = true
      // drawer.classList.add('is-open')
      body.classList.add('is-drawer-open')
      // buttons.forEach((button) => {
      //   button.classList.add('is-open')
      // })
    }

    const close = () => {
      isOpen = false
      // drawer.classList.remove('is-open')
      body.classList.remove('is-drawer-open')
      // buttons.forEach((button) => {
      //   button.classList.remove('is-open')
      // })
    }

    const toggle = () => {
      if (isOpen) {
        close()
      } else {
        open()
      }
    }

    buttons.forEach((button) => {
      button.addEventListener('click', toggle)
    })

    document.addEventListener('click', (e) => {
      if (e.target.closest('#js-drawer') || e.target.closest('.js-drawer-button')) {
        return
      }
      close()
    })
  }
}

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class Header {
  constructor() {
    this.sections = document.querySelectorAll('.js-dark')
    if (this.sections.length === 0) return

    this.body = document.body
    this.header = document.getElementById('js-header')

    document.addEventListener('DOMContentLoaded', () => {
      this.activeTrg()
    })
  }

  activeTrg() {
    this.sections.forEach((section) => {
      gsap.to(this.header, {
        scrollTrigger: {
          trigger: section,
          start: '-1px top',
          end: 'bottom top',
          markers: false,
          onToggle: (self) => {
            this.body.classList.toggle('is-dark', self.isActive)
          },
        },
      })
    })
  }
}

export class InputFile {
  constructor() {
    const files = document.querySelectorAll('.js-file')
    if (!files) return

    // const input_return = document.querySelector('.js-file-return')

    files.forEach((file) => {
      const input = file.querySelector('input')
      const text = file.querySelector('.js-file-text')

      input.addEventListener('change', () => {
        const fileList = input.files
        for (let i = 0; i < fileList.length; i++) {
          text.innerText = fileList[i].name
        }
      })
    })
  }
}

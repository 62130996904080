import Swiper from 'swiper'
import { EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/effect-fade'

export class Tab {
  constructor() {
    this.tabs = document.querySelectorAll('.js-tab')
    if (this.tabs.length === 0) return

    window.addEventListener('load', () => {
      this.initTab()
    })
  }

  initTab() {
    this.tabs.forEach((tab) => {
      const triggers = tab.querySelectorAll('.js-tab-trg')
      const content = tab.querySelector('.js-tab-content')
      let initialSlideNum = 0

      const swiper = new Swiper(content, {
        modules: [EffectFade],
        effect: 'fade',
        initialSlide: initialSlideNum,
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: false,
        autoHeight: true,
      })

      triggers.forEach((trigger, index) => {
        triggers[initialSlideNum].classList.add('is-act')
        trigger.addEventListener('click', () => {
          swiper.slideTo(index)
          triggers.forEach((t) => t.classList.remove('is-act'))
          trigger.classList.add('is-act')
        })
      })

      const acoTriggers = tab.querySelectorAll('.js-aco-trg')
      if (acoTriggers.length === 0) return
      acoTriggers.forEach((trigger) => {
        trigger.addEventListener('click', () => {
          setTimeout(() => {
            swiper.updateAutoHeight(1000)
          }, 300)
        })
      })
    })
  }
}

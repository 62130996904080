import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'

export class EndlessSlider {
  constructor() {
    this.sliders = document.querySelectorAll('.js-endless-slider')
    if (!this.sliders) return

    this.swiper

    window.addEventListener('load', () => {
      this.initSwiper()
    })
  }

  initSwiper = () => {
    this.sliders.forEach((slider) => {
      const container = slider.parentNode

      // ループ用にスライド複製
      const slides = slider.querySelectorAll('.swiper-slide')
      slides.forEach(function (slide) {
        const cloneSlide = slide.cloneNode(true)
        container.querySelector('.swiper-wrapper').appendChild(cloneSlide)
      })

      let speed = 30000 // デフォルトの速度
      let gap = 0
      let autoplayDirection = false

      // data-speed属性がある場合、その値を取得
      const dataSpeed = slider.getAttribute('data-speed')
      if (dataSpeed) {
        speed = parseInt(dataSpeed, 10)
      }

      const dataGap = slider.getAttribute('data-gap')
      if (dataGap) {
        gap = parseInt(dataGap, 10)
      }

      // .-reverseクラスがある場合、autoplayの方向を逆に設定
      if (slider.classList.contains('-reverse')) {
        autoplayDirection = true
      }

      this.swiper = new Swiper(slider, {
        modules: [Autoplay],
        autoplay: {
          delay: 0,
          reverseDirection: autoplayDirection,
        },
        loop: true,
        speed: speed,
        spaceBetween: gap,
        slidesPerView: 'auto',
        allowTouchMove: false,
      })
    })
  }
}

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export class IndexKv {
  constructor() {
    this.bg = document.getElementById('js-index-kv-bg')
    if (this.bg === null) return

    this.kvBg = this.bg.querySelector('.kv')
    this.IntroBg = this.bg.querySelector('.intro')

    this.kv = document.getElementById('js-index-kv')
    this.intro = document.getElementById('js-index-intro')

    this.bg.classList.add('is-kv')

    window.addEventListener('load', () => {
      // 背景を固定
      ScrollTrigger.create({
        trigger: this.kv,
        start: 'top top',
        end: 'bottom bottom',
        endTrigger: this.intro,
        pin: this.bg,
        anticipatePin: 1,
        // markers: true,
      })

      // .introセクションが画面内に入った時のアニメーション
      ScrollTrigger.create({
        trigger: this.kv,
        start: '50% top',
        end: 'bottom bottom',
        // markers: true,
        onEnter: () => {
          this.bg.classList.add('is-intro')
          this.bg.classList.remove('is-kv')
          gsap.to(this.kv, {
            opacity: 0,
            duration: 1,
          })
        },
        onLeaveBack: () => {
          this.bg.classList.add('is-kv')
          this.bg.classList.remove('is-intro')
          gsap.to(this.kv, {
            opacity: 1,
            duration: 1,
          })
        },
      })
    })
  }
}

'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { Tab } from './lib/Tab'
import { Accordion } from './lib/Accordion'
import { IndexNumberSlider } from './lib/IndexNumberSlider'
import { InterviewCardSlider } from './lib/InterviewCardSlider'
import { EndlessSlider } from './lib/EndlessSlider'
import { MegaMenu } from './lib/MegaMenu'
import { Header } from './lib/Header'
import { IndexKv } from './lib/IndexKv'
import { IndexProduct } from './lib/IndexProduct'
import { SplitText } from './lib/SplitText'
import { Loading } from './lib/Loading'
import { InputFile } from './lib/InputFile'
import { AnchorSidebar } from './lib/AnchorSidebar'
import { IndexKvSlider } from './lib/IndexKvSlider'
import { AboutMission } from './lib/AboutMission'
import { ManufacturingMap } from './lib/ManufacturingMap'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new Tab()
new Accordion()
new IndexNumberSlider()
new InterviewCardSlider()
new EndlessSlider()
new MegaMenu()
new Header()
new IndexKv()
new IndexProduct()
new SplitText()
new Loading()
new InputFile()
new AnchorSidebar()
new IndexKvSlider()
new AboutMission()
new ManufacturingMap()

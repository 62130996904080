import Swiper from 'swiper'
import { EffectFade, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'

export class IndexKvSlider {
  constructor() {
    this.slider = document.querySelector('.js-index-kv-slider')
    if (this.slider === null) return
  }

  initSwiper() {
    const swiper = new Swiper(this.slider, {
      modules: [EffectFade, Autoplay],
      slidesPerView: 1,
      allowTouchMove: false,
      loop: true,
      effect: 'fade',
      speed: 2000,
      autoplay: {
        delay: 5000,
        waitForTransition: false,
        disableOnInteraction: false,
      },
      on: {
        init: function () {
          this.autoplay.start()
          console.log('autoplay start')
        },
      },
    })
  }
}

import { gsap } from 'gsap'
import { IndexKvSlider } from './IndexKvSlider'

export class Loading {
  constructor() {
    this.kv = document.getElementById('js-index-kv')
    this.loading = document.getElementById('js-loading')
    this.body = document.body
    this.page = document.getElementById('page')
    this.header = document.getElementById('js-header')
    this.kvSlider = new IndexKvSlider()

    if (this.kv === null) {
      gsap.to(this.page, 1, {
        opacity: 1,
      })
      return
    }

    // falseでローディングスキップ
    let isOpening
    if (this.body.classList.contains('is-opening')) {
      // 初回
      isOpening = true
    } else {
      isOpening = true
    }

    this.bg = document.getElementById('js-index-kv-bg')
    this.copy = this.kv.querySelector('.p-index_kv__copy')
    this.scroll = this.kv.querySelector('.p-index_kv__scroll')

    let tl1 = gsap.timeline()

    this.body.classList.add('is-fixed')
    this.body.classList.add('is-loading')

    document.addEventListener('DOMContentLoaded', () => {
      if (isOpening === true) {
        window.addEventListener('load', () => {
          gsap.to(this.page, 1, {
            opacity: 1,
          })
          tl1.to(
            this.kv,
            {
              onStart: () => {
                this.kv.classList.add('is-act')
                this.body.classList.remove('is-fixed')
                this.body.classList.add('is-loading-end')
                this.kvSlider.initSwiper()
              },
            },
            '+=0.5'
          )
          tl1.from(
            this.bg,
            {
              duration: 1.5,
              opacity: 0,
              scale: 1.05,
              filter: 'blur(10px)',
              onStart: () => {
                this.bg.classList.add('is-act')
              },
            },
            '<'
          )
          tl1.from(
            this.copy,
            {
              duration: 1.5,
              opacity: 0,
              y: '0.2em',
              onStart: () => {
                this.copy.classList.add('is-act')
              },
            },
            '-=0.5'
          )
          tl1.from(
            this.header,
            {
              duration: 1,
              opacity: 0,
              y: -50,
            },
            '<'
          )
          tl1.from(
            this.scroll,
            {
              duration: 1,
              opacity: 0,
            },
            '<'
          )
        })
      } else {
        gsap.to(this.page, 1, {
          opacity: 1,
        })
      }
    })
  }
}

import Swiper from 'swiper'
import { Navigation, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/scrollbar'

export class InterviewCardSlider {
  constructor() {
    this.slider = document.querySelector('.js-interview-card-slider')
    if (this.slider === null) return

    this.container = this.slider.parentNode
    this.buttonPrev = this.container.querySelector('.js-slider-button.-prev')
    this.buttonNext = this.container.querySelector('.js-slider-button.-next')
    this.scrollbar = this.container.querySelector('.js-slider-scrollbar')

    this.initSwiper()
  }

  initSwiper() {
    const swiper = new Swiper(this.slider, {
      modules: [Navigation, Scrollbar],
      slidesPerView: 'auto',
      spaceBetween: 30,
      navigation: {
        prevEl: this.buttonPrev,
        nextEl: this.buttonNext,
      },
      scrollbar: {
        el: this.scrollbar,
        draggable: true,
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    })
  }
}

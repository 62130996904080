export class SplitText {
  constructor() {
    const textContainers = document.querySelectorAll('.js-split-text')
    if (textContainers.length === 0) return

    textContainers.forEach((textContainer) => {
      const delayData = textContainer.getAttribute('data-delay')
      const text = textContainer.textContent.trim()
      const splitText = text.split('')

      textContainer.innerHTML = ''

      splitText.forEach((char, index) => {
        const span = document.createElement('span')
        span.textContent = char

        let delay = index * 0.1
        if (delayData) {
          delay = parseFloat(delayData) + index * 0.1
        }

        span.style.transitionDelay = `${delay}s`

        textContainer.appendChild(span)
      })
    })
  }
}

export class MegaMenu {
  constructor() {
    const megaArr = document.querySelectorAll('.js-mega')
    if (!megaArr) return

    megaArr.forEach((mega) => {
      // const megaTrg = mega
      const megaTrg = mega.querySelector('.js-mega-trg')
      const megaElm = mega.querySelector('.js-mega-elm')

      // ホバー
      megaTrg.addEventListener('mouseover', () => {
        mega.classList.add('is-act')
      })
      megaElm.addEventListener('mouseover', () => {
        mega.classList.add('is-act')
      })
      megaTrg.addEventListener('mouseout', () => {
        mega.classList.remove('is-act')
      })
      megaElm.addEventListener('mouseout', () => {
        mega.classList.remove('is-act')
      })

      // タッチ
      megaTrg.addEventListener('touchstart', () => {
        mega.classList.toggle('is-act')
      })
    })
  }
}

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export class IndexProduct {
  constructor() {
    this.section = document.getElementById('js-index-product')
    if (this.section === null) return

    this.wrapper = document.querySelector('.js-index-product-wrapper')
    this.illustWrapper = document.querySelector('.js-index-product-illust-wrapper')
    this.illust = document.querySelector('.js-index-product-illust')
    this.content = document.querySelector('.js-index-product-content')

    this.sectionHeight = this.section.offsetHeight

    const setScrollTrigger = (trigger, start, scrub = 0, onEnterCallback = null) => {
      gsap.to(trigger, {
        scale: 1.2,
        // ease: 'ease',
        duration: 1,
        scrollTrigger: {
          trigger: this.illustWrapper,
          start: start,
          scrub: scrub,
          onEnter: onEnterCallback,
        },
      })
    }

    const setPinScrollTrigger = (trigger, start, end, pinElement) => {
      ScrollTrigger.create({
        trigger: trigger,
        start: start,
        end: end,
        pin: pinElement,
        pinSpacing: false,
      })
    }

    const setActiveToggleTrigger = (trigger, start, end, toggleClass) => {
      ScrollTrigger.create({
        trigger: trigger,
        start: start,
        end: end,
        // markers:true,
        onToggle: (self) => {
          this.wrapper.classList.toggle(toggleClass, self.isActive)
        },
      })
    }

    const activePcAnimation = () => {
      this.section.style.height = this.sectionHeight * 4 + 'px'

      setScrollTrigger(this.illust, 'top center', 0, () => {
        this.wrapper.classList.add('is-act')
      })

      setPinScrollTrigger(this.wrapper, 'top top', 'bottom bottom', this.section)
      setActiveToggleTrigger(this.wrapper, this.sectionHeight + 'top', 'bottom top', 'is-illust-act')
      setActiveToggleTrigger(this.wrapper, this.sectionHeight * 2 + 'top', 'bottom top', 'is-content-act')
    }

    const activeSpAnimation = () => {
      this.wrapper.appendChild(this.content)
      this.section.style.height = this.sectionHeight * 1 + 'px'

      setScrollTrigger(this.illust, 'top center', 0, () => {
        this.wrapper.classList.add('is-act')
      })

      setPinScrollTrigger(this.section, 'top top', 'bottom bottom')
      setActiveToggleTrigger(this.wrapper, this.sectionHeight + 'top', 'bottom top', 'is-illust-act')
      setActiveToggleTrigger(this.wrapper, this.sectionHeight * .2 + 'top', 'bottom top', 'is-content-act')
    }

    const mediaQuery = window.matchMedia('(max-width:767px)')

    const checkBreakpoint = (event) => {
      if (event.matches) {
        activeSpAnimation()
      } else {
        activePcAnimation()
      }
    }

    mediaQuery.addListener(checkBreakpoint)
    window.addEventListener('load', () => {
      checkBreakpoint(mediaQuery)
    })

    let lastWindowWidth = window.innerWidth

    window.addEventListener('resize', () => {
      if (lastWindowWidth != window.innerWidth) {
        lastWindowWidth = window.innerWidth
        window.location.reload()
      }
    })
  }
}
